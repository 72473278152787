import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { OffersSlider } from "src/components/OffersSlider"
import { Header } from "src/components/Header"
import { getFluidImageSrc } from "src/utils/images"
import SEO from "src/components/SEO"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { InPageMenu } from "src/components/InPageMenu"
import InViewMonitor from "react-inview-monitor"

const PageSimple: React.FC<any> = ({ data: { mdx }, pageContext }) => {
  if (!mdx || !mdx.body) {
    return <div>No content has been created for this page</div>
  }

  const { title: pageTitle, headerImage, videoFileName, videoFileNameMobile } = mdx.frontmatter

  const parentId = pageContext && pageContext.parentId
  const pageId = pageContext && pageContext.pageId

  return (
    <Fragment>
      <SEO title={pageTitle} />

      {headerImage || videoFileName ? (
        <Header
          backgroundImageUrl={getFluidImageSrc(headerImage) || ""}
          videoName={videoFileName}
          videoNameMobile={videoFileNameMobile}
        />
      ) : null}

      <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp">
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </InViewMonitor>

      <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp">
        <InPageMenu currentPageParentId={parentId} currentPageId={pageId} />
      </InViewMonitor>

      <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp">
        <OffersSlider />
      </InViewMonitor>
    </Fragment>
  )
}

export default PageSimple

export const query = graphql`
  query PageSimple($locale: String!, $title: String!) {
    mdx(frontmatter: { title: { eq: $title } }, fields: { locale: { eq: $locale } }) {
      frontmatter {
        title
        headerImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1900) {
              ...GatsbyImageSharpFluid
              srcWebp
            }
          }
        }
        videoFileName
        videoFileNameMobile
      }
      body
    }
  }
`
