import React, { useState } from "react"
import { Container, Col, Card, CardHeader, Collapse, CardBody } from "src/ui"
import { graphql, StaticQuery } from "gatsby"
import { PageContext } from "ExtendiApp"
import { LocaleContext } from "../layout"
import LocalizedLink from "../localizedLink"
import useTranslations from "../useTranslations"
import arrow from "src/ui/img/ico_arrow.svg"

type PageId =
  | "clienti"
  | "family-hotel"
  | "hotel-residence"
  | "posizione-dintorni"
  | "richieste-prenotazione"
  | "sport-bike-hotel"

interface Props {
  currentPageParentId: PageId
  currentPageId: string
}

export const InPageMenu: React.FC<Props> = ({ currentPageParentId, currentPageId }) => {
  const interested = useTranslations("interested")
  const { locale } = React.useContext(LocaleContext)

  const query = graphql`
    query InPageMenu {
      allSitePage {
        nodes {
          context {
            title
            locale
            slug
            pageId
            menuLevel
            menuOrder
            parentId
          }
        }
      }
    }
  `

  // correlazioni tra pagine
  const secondaryItemMap: { [key in PageId]: PageId[] } = {
    "family-hotel": ["sport-bike-hotel"],
    "sport-bike-hotel": ["family-hotel"],
    clienti: ["hotel-residence"],
    "hotel-residence": ["clienti"],
    "posizione-dintorni": ["richieste-prenotazione"],
    "richieste-prenotazione": ["posizione-dintorni"],
  }
  const showForOtherPageIds = secondaryItemMap[currentPageParentId]

  const [openCardIndex, setOpenCardIndex] = useState<number | null>(null)

  return (
    <StaticQuery
      query={query}
      render={data => {
        const {
          allSitePage: { nodes: pages },
        } = data

        // flatten content
        const allPages: PageContext[] = pages.map(page => page.context)
        const localePages = allPages
          .filter(page => page && page.locale === locale)
          .filter(page => Boolean(page.slug))
          .sort(sortPages)
        const rootLevel = localePages.filter(page => page.parentId === null).sort(sortPages)

        const collapseItems = [
          ...rootLevel.filter(page => page.pageId === currentPageParentId),
          ...rootLevel.filter(page =>
            showForOtherPageIds ? showForOtherPageIds.includes(page.pageId as PageId) : false
          ),
        ]

        if (!collapseItems || !collapseItems.length) {
          return null
        }

        return (
          <section className={"section section-default bg-secondary bg-hotel"}>
            <div className="texture-overlay">
              <div className="bg-texture"></div>
            </div>
            <Container>
              <Col sm={{ size: 8, offset: 2 }}>
                <h2 className="text-primary text-center pb-2 h1">{interested}</h2>
                <div className="toggler">
                  {collapseItems.map((level0, cardIndex) => {
                    return (
                      <Card
                        key={cardIndex}
                        className={`bg-transparent ${
                          openCardIndex === cardIndex ? "collapsable-card-open" : "collapsable-card-closed"
                        }`}
                      >
                        <CardHeader
                          className="d-flex justify-content-between align-items-center p-4 no-bg"
                          onClick={() => {
                            if (openCardIndex == cardIndex) {
                              setOpenCardIndex(null)
                            } else {
                              setOpenCardIndex(cardIndex)
                            }
                          }}
                        >
                          <h3>{level0.title}</h3>
                          <img src={arrow} className="svg" alt="" />
                        </CardHeader>
                        <Collapse isOpen={openCardIndex === cardIndex}>
                          <CardBody className="p-0 pb-3">
                            <ul className="list-unstyled ml-4">
                              {localePages
                                .filter(page => page.parentId === level0.pageId)
                                .filter(page => page.pageId !== currentPageId)
                                .map(level1 => (
                                  <li key={level1.pageId}>
                                    <LocalizedLink to={level1.slug}>{level1.title}</LocalizedLink>
                                  </li>
                                ))}
                            </ul>
                          </CardBody>
                        </Collapse>
                      </Card>
                    )
                  })}
                </div>
              </Col>
            </Container>
          </section>
        )
      }}
    />
  )
}

const sortPages = (a: PageContext, b: PageContext) => (a.menuOrder > b.menuOrder ? 1 : -1)
